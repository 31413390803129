/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import referencesApi from "~/api/references";
import ROUTE from "~/constants/routes";
import router from "~/router";
import {
  templatesTypesTableList,
  templatesStatusesTableList,
  templatesFormatsTableList,
  templatesTagsTableList,
  templatesFontsTableList,
} from "~/helpers/dataIteration";

Vue.use(Vuex);

const state = {
  countAll: 0,
  templatesStatuses: [],
  templatesStatusesList: [],
  templatesStatus: {},
  templatesTypes: {},
  templatesTypesList: [],
  templatesType: {},
  templatesFormats: [],
  templatesFormatsList: [],
  templatesFormat: {},
  templatesTags: [],
  templatesTagsList: [],
  templatesTag: {},
  templatesSizes: [],
  templatesSizesList: [],
  templatesSize: {},
  templatesFonts: [],
  templatesFontsList: [],
  templatesFont: {},
  templatesColor: [],
  templatesColorList: [],
  templatesStyle: [],
  templatesStyleList: [],

  layoutFonts: {},
  layoutFontsList: [],

  plansItemsTypes: {},
  plansItemsTypesList: [],

  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setCountAll: (state, payload) => {
    state.countAll = payload;
  },
  setTemplatesTypes: (state, payload) => {
    state.templatesTypes = payload;
  },
  setTemplatesTypesList: (state, payload) => {
    state.templatesTypesList = payload;
  },
  setTemplatesType: (state, payload) => {
    state.templatesType = payload;
  },
  setTemplatesStatuses: (state, payload) => {
    state.templatesStatuses = payload;
  },
  setTemplatesStatusesList: (state, payload) => {
    state.templatesStatusesList = payload;
  },
  setTemplatesStatus: (state, payload) => {
    state.templatesStatus = payload;
  },
  setTemplatesFormats: (state, payload) => {
    state.templatesFormats = payload;
  },
  setTemplatesFormatsList: (state, payload) => {
    state.templatesFormatsList = payload;
  },
  setTemplatesFormat: (state, payload) => {
    state.templatesFormat = payload;
  },
  setTemplatesTags: (state, payload) => {
    state.templatesTags = payload;
  },
  setTemplatesTagsList: (state, payload) => {
    state.templatesTagsList = payload;
  },
  setTemplatesTag: (state, payload) => {
    state.templatesTag = payload;
  },
  setTemplatesSizes: (state, payload) => {
    state.templatesSizes = payload;
  },
  setTemplatesSizesList: (state, payload) => {
    state.templatesSizesList = payload;
  },
  setTemplatesSize: (state, payload) => {
    state.templatesSize = payload;
  },
  setTemplatesFonts: (state, payload) => {
    state.templatesFonts = payload;
  },
  setTemplatesFontsList: (state, payload) => {
    state.templatesFontsList = payload;
  },
  setTemplatesFont: (state, payload) => {
    state.templatesFont = payload;
  },
  setTemplatesColor: (state, payload) => {
    state.templatesColor = payload;
  },
  setTemplatesColorList: (state, payload) => {
    state.templatesColorList = payload;
  },
  setTemplatesStyle: (state, payload) => {
    state.templatesStyle = payload;
  },
  setTemplatesStyleList: (state, payload) => {
    state.templatesStyleList = payload;
  },

  setLayoutFonts: (state, payload) => {
    state.layoutFonts = payload;
  },
  setLayoutFontsList: (state, payload) => {
    state.layoutFontsList = payload;
  },

  setPlansItemsTypes: (state, payload) => {
    state.plansItemsTypes = payload;
  },
  setPlansItemsTypesList: (state, payload) => {
    state.plansItemsTypesList = payload;
  },

  setError: (state, payload) => {
    state.error = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
};

const actions = {
  // Templates Types Start

  getTemplatesTypes({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesTypes(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesTypes", res.data[0]);
        commit("setTemplatesTypesList", templatesTypesTableList(res.data[0].data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createTemplatesType({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .createTemplatesType(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_TYPES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getItemTemplatesType({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getItemTemplatesType(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesTypes", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editTemplatesType({ commit }, { url, editData }) {
    commit("setLoading", true);
    referencesApi
      .editTemplatesType(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_TYPES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Templates Types End

  // Templates Statuses Start

  getTemplatesStatuses({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesStatuses(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesStatuses", res.data[0]);
        commit("setCountAll", res.data.countAll);
        commit("setTemplatesStatusesList", templatesStatusesTableList(res.data[0].data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createTemplatesStatus({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .createTemplatesStatus(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_STATUSES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getItemTemplatesStatus({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getItemTemplatesStatus(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesStatus", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editTemplatesStatus({ commit }, { url, editData }) {
    commit("setLoading", true);
    referencesApi
      .editTemplatesStatus(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_STATUSES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Templates Statuses End

  // Templates Formats Start

  getTemplatesFormats({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesFormats(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesFormats", res.data[0]);
        commit("setTemplatesFormatsList", templatesFormatsTableList(res.data[0].data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createTemplatesFormat({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .createTemplatesFormat(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_FORMATS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getItemTemplatesFormat({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getItemTemplatesFormat(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesFormat", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editTemplatesFormat({ commit }, { url, editData }) {
    commit("setLoading", true);
    referencesApi
      .editTemplatesFormat(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_FORMATS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Templates Formats End

  // Templates Tags Start

  getTemplatesTags({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesTags(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesTags", res.data[0]);
        commit("setTemplatesTagsList", templatesTagsTableList(res.data[0].data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createTemplatesTag({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .createTemplatesTag(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_TAGS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getItemTemplatesTag({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getItemTemplatesTag(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesTag", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editTemplatesTag({ commit }, { url, editData }) {
    commit("setLoading", true);
    referencesApi
      .editTemplatesTag(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_TAGS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Templates Tags End

  // Templates Fonts Start

  getTemplatesFonts({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesFonts(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesFonts", res.data[0]);
        commit("setTemplatesFontsList", templatesFontsTableList(res.data[0].data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  filterTemplatesFonts({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .filterTemplatesFonts(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesFonts", res.data[0]);
        commit("setTemplatesFontsList", templatesFontsTableList(res.data[0].data));
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createTemplatesFont({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .createTemplatesFont(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_FONTS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getItemTemplatesFont({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getItemTemplatesFont(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesFont", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editTemplatesFont({ commit }, { url, editData }) {
    commit("setLoading", true);
    referencesApi
      .editTemplatesFont(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_FONTS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Templates Fonts End

  // Templates Color Start

  getTemplatesColor({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesColor(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesColor", res.data[0]);
        commit("setTemplatesColorList", res.data[0].data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createTemplatesColor({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .createTemplatesColor(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_COLOR_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editTemplatesColor({ commit }, { url, editData }) {
    commit("setLoading", true);
    referencesApi
      .editTemplatesColor(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_COLOR_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Templates Style Start

  getTemplatesStyle({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesStyle(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesStyle", res.data[0]);
        commit("setTemplatesStyleList", res.data[0].data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getTemplatesStyleAll({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesStyleAll(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesStyleList", res.data.items);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createTemplatesStyle({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .createTemplatesStyle(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_STYLE_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editTemplatesStyle({ commit }, { url, editData }) {
    commit("setLoading", true);
    referencesApi
      .editTemplatesStyle(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_STYLE_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Templates Style End

  // Templates Sizes Start

  getTemplatesSizes({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesSizes(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesSizes", res.data[0]);
        commit("setTemplatesSizesList", res.data[0].data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getTemplatesSizesAll({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getTemplatesSizesAll(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesSizesList", res.data.items);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createTemplatesSizes({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .createTemplatesSizes(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_SIZES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getItemTemplatesSizes({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getItemTemplatesSizes(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesSize", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editTemplatesSizes({ commit }, { url, editData }) {
    commit("setLoading", true);
    referencesApi
      .editTemplatesSizes(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_SIZES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Templates Sizes End

  // Layout Fonts Start

  getLayoutFonts({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getLayoutFonts(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setLayoutFonts", res.data[0]);
        commit("setLayoutFontsList", res.data[0].data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createLayoutFonts({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .createLayoutFonts(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.LAYOUT_FONTS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editLayoutFonts({ commit }, { url, editData }) {
    commit("setLoading", true);
    referencesApi
      .editLayoutFonts(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.LAYOUT_FONTS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Layout Fonts End

  // Plans Items Types Start

  getPlansItemsTypes({ commit }, payload) {
    commit("setLoading", true);
    referencesApi
      .getPlansItemsTypes()
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setPlansItemsTypes", res.data.items);
        commit("setPlansItemsTypesList", res.data.items);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Plans Items Types End
};

const getters = {};

const references = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default references;
