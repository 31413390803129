/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import templatesApi from "~/api/templates";
import ROUTE from "~/constants/routes";
import router from "~/router";
import { templatesTableList } from "~/helpers/dataIteration";

import moment from "moment";

Vue.use(Vuex);

const state = {
  templates: {},
  templatesList: [],

  templatesStatics: null,
  templatesStaticsList: [],

  plansList: [],
  plans: null,

  keywordsList: [],
  keywords: null,

  template: null,
  error: {},
  validationErrors: {},
  loadingProcess: false,
};

const mutations = {
  setTemplatesStaticsList: (state, payload) => {
    state.templatesStaticsList = payload;
  },
  setTemplatesStatics: (state, payload) => {
    state.templatesStatics = payload;
  },

  setTemplatesList: (state, payload) => {
    state.templatesList = payload;
  },
  setTemplates: (state, payload) => {
    state.templates = payload;
  },

  setPlansList: (state, payload) => {
    state.plansList = payload;
  },
  setPlans: (state, payload) => {
    state.plans = payload;
  },

  setKeywordsList: (state, payload) => {
    state.keywordsList = payload;
  },
  setKeywords: (state, payload) => {
    state.keywords = payload;
  },

  setTemplate: (state, payload) => {
    state.template = payload;
  },
  setError: (state, payload) => {
    state.error = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
};

const actions = {
  getTemplatesList({ commit }, payload) {
    commit("setLoading", true);
    templatesApi
      .getTemplatesList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesList", templatesTableList(res.data[0].data));
        commit("setTemplates", res.data[0]);
      })
      .catch((error) => {
        console.log(error);
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getTemplatesStatisticsList({ commit }, payload) {
    commit("setLoading", true);
    templatesApi
      .getTemplatesStatistics(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplatesStatics", res.data.items);
        const list = Object.values(res.data.items);
        for (let i = 0; list.length > i; i++) {
          list[i].group = list[i].groups[0].name;

          const statuses = Object.keys(list[i].statuses);
          for (let a = 0; statuses.length > a; a++) {
            list[i][statuses[a]] = list[i].statuses[statuses[a]];
          }
        }
        commit("setTemplatesStaticsList", list);
      })
      .catch((error) => {
        console.log(error);
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getItemTemplate({ commit }, payload) {
    commit("setLoading", true);
    templatesApi
      .getItemTemplate(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        commit("setTemplate", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createTemplate({ commit }, payload) {
    commit("setLoading", true);
    templatesApi
      .createTemplate(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editTemplate({ commit }, { url, editData }) {
    commit("setLoading", true);
    templatesApi
      .editTemplate(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.TEMPLATES_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  templateCheckSeo({ commit }, payload) {
    commit("setLoading", true);
    templatesApi
      .templateCheckSeo(payload)
      .then((res) => {
        if (!res.data.exists) {
          commit("setError", {});
          commit("setValidationErrors", {});
        } else {
          commit("setValidationErrors", {
            seoName: ["The SEO Name of Template has already been taken."],
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Content Plans

  getPlansList({ commit }, payload) {
    commit("setLoading", true);
    templatesApi
      .getPlansList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const plans = res.data[0].data;

        for (let i = 0; plans.length > i; i++) {
          plans[i].month = moment(plans[i].endDate).format("MMMM");
          plans[i].year = moment(plans[i].endDate).format("YYYY");
        }
        commit("setPlansList", plans);
        commit("setPlans", res.data[0]);
      })
      .catch((error) => {
        console.log(error);
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  // Keywords

  getKeywordsList({ commit }, payload) {
    commit("setLoading", true);
    templatesApi
      .getKeywordsList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const keywords = res.data[0].data;

        for (let i = 0; keywords.length > i; i++) {
          if (keywords[i].parentId) {
            keywords[i].type = "child";
          } else {
            keywords[i].type = "parent";
          }

          keywords[i].category_name = keywords[i].category[0].fullName.replace("Freebies / ", "");
        }
        commit("setKeywordsList", keywords);
        commit("setKeywords", res.data[0]);
      })
      .catch((error) => {
        console.log(error);
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createKeywords({ commit }, payload) {
    commit("setLoading", true);
    templatesApi
      .createKeywords(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.KEYWORDS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createKeywordsArray({ commit }, payload) {
    commit("setLoading", true);
    templatesApi
      .createKeywordsArray(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.KEYWORDS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
          console.log(data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editKeywords({ commit }, { url, editData }) {
    commit("setLoading", true);
    templatesApi
      .editKeywords(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.KEYWORDS_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const templates = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default templates;
