import Vue from "vue";
import VueRouter from "vue-router";
import ROUTE from "~/constants/routes";
import { getStoredUserInfo } from "../helpers/userAuth";

const Login = () => import("~/pages/Login.vue");
const Home = () => import("~/pages/Home.vue");
const PageNotFound = () => import("~/pages/error/PageNotFound.vue");

const CategoriesGroupsList = () => import("~/pages/categories/groups/CategoriesGroupsList.vue");
const CategoriesGroupCreate = () => import("~/pages/categories/groups/CategoriesGroupCreate.vue");
const CategoriesGroupEdit = () => import("~/pages/categories/groups/CategoriesGroupEdit.vue");

const CategoriesList = () => import("~/pages/categories/items/CategoriesList.vue");
const CategoryCreate = () => import("~/pages/categories/items/CategoryCreate.vue");
const CategoryEdit = () => import("~/pages/categories/items/CategoryEdit.vue");

const TemplatesList = () => import("~/pages/templates/TemplatesList.vue");
const TemplateCreate = () => import("~/pages/templates/TemplateCreate.vue");
const TemplateEdit = () => import("~/pages/templates/TemplateEdit.vue");

const TemplatesEditQuick = () => import("~/pages/templates/TemplatesEditQuick.vue");

const TemplatesStatistics = () => import("~/pages/statistics/templatesStatistics.vue");

const TemplatesTypesList = () => import("~/pages/references/types/TemplatesTypesList.vue");
const TemplatesTypeCreate = () => import("~/pages/references/types/TemplatesTypeCreate.vue");
const TemplatesTypeEdit = () => import("~/pages/references/types/TemplatesTypeEdit.vue");

const TemplatesStatusesList = () => import("~/pages/references/statuses/TemplatesStatusesList.vue");
const TemplatesStatusCreate = () => import("~/pages/references/statuses/TemplatesStatusCreate.vue");
const TemplatesStatusEdit = () => import("~/pages/references/statuses/TemplatesStatusEdit.vue");

const TemplatesFormatsList = () => import("~/pages/references/formats/TemplatesFormatsList.vue");
const TemplatesFormatCreate = () => import("~/pages/references/formats/TemplatesFormatCreate.vue");
const TemplatesFormatEdit = () => import("~/pages/references/formats/TemplatesFormatEdit.vue");

const TemplatesTagsList = () => import("~/pages/references/tags/TemplatesTagsList.vue");
const TemplatesTagCreate = () => import("~/pages/references/tags/TemplatesTagCreate.vue");
const TemplatesTagEdit = () => import("~/pages/references/tags/TemplatesTagEdit.vue");

const TemplatesStyleList = () => import("~/pages/references/style/TemplatesStyleList.vue");
const TemplatesStyleCreate = () => import("~/pages/references/style/TemplatesStyleCreate.vue");
const TemplatesStyleEdit = () => import("~/pages/references/style/TemplatesStyleEdit.vue");

const TemplatesColorList = () => import("~/pages/references/color/TemplatesColorList.vue");
const TemplatesColorCreate = () => import("~/pages/references/color/TemplatesColorCreate.vue");
const TemplatesColorEdit = () => import("~/pages/references/color/TemplatesColorEdit.vue");

const TemplatesFontsList = () => import("~/pages/references/fonts/TemplatesFontsList.vue");
const TemplatesFontCreate = () => import("~/pages/references/fonts/TemplatesFontCreate.vue");
const TemplatesFontEdit = () => import("~/pages/references/fonts/TemplatesFontEdit.vue");

const TemplatesSizesList = () => import("~/pages/references/sizes/TemplatesSizesList.vue");
const TemplatesSizesCreate = () => import("~/pages/references/sizes/TemplatesSizesCreate.vue");
const TemplatesSizesEdit = () => import("~/pages/references/sizes/TemplatesSizesEdit.vue");

const LayoutFontsList = () => import("~/pages/references/layoutFonts/list.vue");
const LayoutFontsCreate = () => import("~/pages/references/layoutFonts/create.vue");
const LayoutFontsEdit = () => import("~/pages/references/layoutFonts/edit.vue");

const BlogGroupsList = () => import("~/pages/blog/groups/BlogGroupsList.vue");
const BlogGroupCreate = () => import("~/pages/blog/groups/BlogGroupCreate.vue");
const BlogGroupEdit = () => import("~/pages/blog/groups/BlogGroupEdit.vue");

const BlogCategoriesList = () => import("~/pages/blog/categories/BlogCategoriesList.vue");
const BlogCategoryCreate = () => import("~/pages/blog/categories/BlogCategoryCreate.vue");
const BlogCategoryEdit = () => import("~/pages/blog/categories/BlogCategoryEdit.vue");

const BlogPostsList = () => import("~/pages/blog/items/BlogPostsList.vue");
const BlogPostCreate = () => import("~/pages/blog/items/BlogPostCreate.vue");
const BlogPostEdit = () => import("~/pages/blog/items/BlogPostEdit.vue");

const BlogPostStatusesList = () => import("~/pages/blog/statuses/BlogStatusesList.vue");
const BlogPostStatusCreate = () => import("~/pages/blog/statuses/BlogStatusCreate.vue");
const BlogPostStatusEdit = () => import("~/pages/blog/statuses/BlogStatusEdit.vue");

const UsersList = () => import("~/pages/users/item/usersList.vue");
const UserCreate = () => import("~/pages/users/item/userCreate.vue");
const UserEdit = () => import("~/pages/users/item/userEdit.vue");
const UserDetail = () => import("~/pages/users/item/userDetail.vue");

const UserGroupsList = () => import("~/pages/users/group/userGroupsList.vue");
const UserGroupCreate = () => import("~/pages/users/group/userGroupCreate.vue");
const UserGroupEdit = () => import("~/pages/users/group/userGroupEdit.vue");

const SubscriptionList = () => import("~/pages/subscription/subscriptionList.vue");
const SubscriptionCreate = () => import("~/pages/subscription/subscriptionCreate.vue");
const SubscriptionEdit = () => import("~/pages/subscription/subscriptionEdit.vue");

const FaqGroupsList = () => import("~/pages/faq/group/faqGroupsList.vue");
const FaqGroupCreate = () => import("~/pages/faq/group/faqGroupCreate.vue");
const FaqGroupEdit = () => import("~/pages/faq/group/faqGroupEdit.vue");

const FaqItemsList = () => import("~/pages/faq/item/faqItemsList.vue");
const FaqItemCreate = () => import("~/pages/faq/item/faqItemCreate.vue");
const FaqItemEdit = () => import("~/pages/faq/item/faqItemEdit.vue");

const StaticsList = () => import("~/pages/static/staticList.vue");
const StaticsCreate = () => import("~/pages/static/staticCreate.vue");
const StaticsEdit = () => import("~/pages/static/staticEdit.vue");

const fbList = () => import("~/pages/social/fbList.vue");

const IndexingList = () => import("~/pages/indexing/indexingList.vue");

const EditorCreate = () => import("~/pages/editor/editorCreate");

const LangList = () => import("~/pages/lang/langList.vue");
const LangCreate = () => import("~/pages/lang/langCreate.vue");
const LangEdit = () => import("~/pages/lang/langEdit.vue");

const SeoList = () => import("~/pages/seo/list.vue");
const SeoCreate = () => import("~/pages/seo/create.vue");
const SeoEdit = () => import("~/pages/seo/edit.vue");

const PlansList = () => import("~/pages/plans/list.vue");
const PlanItemsList = () => import("~/pages/plans/item.vue");

const KeywordsList = () => import("~/pages/keywords/list.vue");
const KeywordsCreate = () => import("~/pages/keywords/create.vue");
const KeywordsCreateArray = () => import("~/pages/keywords/createArray.vue");
const KeywordsEdit = () => import("~/pages/keywords/edit.vue");

Vue.use(VueRouter);

const checkPrivateRoute = (to, from, next) => {
  if (getStoredUserInfo() === null) return next(ROUTE.LOGIN_PAGE);
  return next();
};
const checkLeavePage = (to, from, next) => {
  const answer = window.confirm("You have unsaved data. Are you sure you want to delete the page?");
  if (answer) {
    next();
  } else {
    next(false);
  }
};

const routes = [
  {
    path: ROUTE.LOGIN_PAGE,
    name: "Login",
    component: Login,
  },

  // Home page

  {
    path: ROUTE.HOME_PAGE,
    name: "HOME",
    component: Home,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Categories Groups Pages

  {
    path: ROUTE.CATEGORIES_GROUPS_LIST,
    name: "CategoriesGroupsList",
    component: CategoriesGroupsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.CATEGORIES_GROUP_CREATE,
    name: "CategoriesGroupCreate",
    component: CategoriesGroupCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.CATEGORIES_GROUP_EDIT,
    name: "CategoriesGroupEdit",
    component: CategoriesGroupEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Categories Pages

  {
    path: ROUTE.CATEGORIES_LIST,
    name: "CategoriesList",
    component: CategoriesList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.CATEGORY_CREATE,
    name: "CategoryCreate",
    component: CategoryCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.CATEGORY_EDIT,
    name: "CategoryEdit",
    component: CategoryEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Templates Pages

  {
    path: ROUTE.TEMPLATES_LIST,
    name: "TemplatesList",
    component: TemplatesList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATE_CREATE,
    name: "TemplateCreate",
    component: TemplateCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATE_EDIT,
    name: "TemplateEdit",
    component: TemplateEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  {
    path: ROUTE.TEMPLATE_QUICK_EDIT,
    name: "TemplatesEditQuick",
    component: TemplatesEditQuick,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  {
    path: ROUTE.TEMPLATES_STATISTICS,
    name: "TemplatesStatistics",
    component: TemplatesStatistics,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Templates Types Pages

  {
    path: ROUTE.TEMPLATES_TYPES_LIST,
    name: "TemplatesTypesList",
    component: TemplatesTypesList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_TYPE_CREATE,
    name: "TemplatesTypeCreate",
    component: TemplatesTypeCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_TYPE_EDIT,
    name: "TemplatesTypeEdit",
    component: TemplatesTypeEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Templates Statuses Pages

  {
    path: ROUTE.TEMPLATES_STATUSES_LIST,
    name: "TemplatesStatusesList",
    component: TemplatesStatusesList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_STATUS_CREATE,
    name: "TemplatesStatusCreate",
    component: TemplatesStatusCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_STATUS_EDIT,
    name: "TemplatesStatusEdit",
    component: TemplatesStatusEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Templates Formats Pages

  {
    path: ROUTE.TEMPLATES_FORMATS_LIST,
    name: "TemplatesFormatsList",
    component: TemplatesFormatsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_FORMAT_CREATE,
    name: "TemplatesFormatCreate",
    component: TemplatesFormatCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_FORMAT_EDIT,
    name: "TemplatesFormatEdit",
    component: TemplatesFormatEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Templates Tags Pages

  {
    path: ROUTE.TEMPLATES_TAGS_LIST,
    name: "TemplatesTagsList",
    component: TemplatesTagsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_TAG_CREATE,
    name: "TemplatesTagCreate",
    component: TemplatesTagCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_TAG_EDIT,
    name: "TemplatesTagEdit",
    component: TemplatesTagEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Templates Style Pages

  {
    path: ROUTE.TEMPLATES_STYLE_LIST,
    name: "TemplatesStyleList",
    component: TemplatesStyleList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_STYLE_CREATE,
    name: "TemplatesStyleCreate",
    component: TemplatesStyleCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_STYLE_EDIT,
    name: "TemplatesStyleEdit",
    component: TemplatesStyleEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Templates Color Pages

  {
    path: ROUTE.TEMPLATES_COLOR_LIST,
    name: "TemplatesColorList",
    component: TemplatesColorList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_COLOR_CREATE,
    name: "TemplatesColorCreate",
    component: TemplatesColorCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_COLOR_EDIT,
    name: "TemplatesColorEdit",
    component: TemplatesColorEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Templates Sizes Pages

  {
    path: ROUTE.TEMPLATES_SIZES_LIST,
    name: "TemplatesSizesList",
    component: TemplatesSizesList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_SIZES_CREATE,
    name: "TemplatesSizesCreate",
    component: TemplatesSizesCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_SIZES_EDIT,
    name: "TemplatesSizesEdit",
    component: TemplatesSizesEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Templates Fonts Pages

  {
    path: ROUTE.TEMPLATES_FONTS_LIST,
    name: "TemplatesFontsList",
    component: TemplatesFontsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_FONT_CREATE,
    name: "TemplatesFontsCreate",
    component: TemplatesFontCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.TEMPLATES_FONT_EDIT,
    name: "TemplatesFontEdit",
    component: TemplatesFontEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Layout Fonts Pages

  {
    path: ROUTE.LAYOUT_FONTS_LIST,
    name: "LayoutFontsList",
    component: LayoutFontsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.LAYOUT_FONTS_CREATE,
    name: "LayoutFontsCreate",
    component: LayoutFontsCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.LAYOUT_FONTS_EDIT,
    name: "LayoutFontsEdit",
    component: LayoutFontsEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Blog Pages

  {
    path: ROUTE.BLOG_GROUPS_LIST,
    name: "BlogGroupsList",
    component: BlogGroupsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.BLOG_GROUP_CREATE,
    name: "BlogGroupCreate",
    component: BlogGroupCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.BLOG_GROUP_EDIT,
    name: "BlogGroupEdit",
    component: BlogGroupEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  {
    path: ROUTE.BLOG_CATEGORIES_LIST,
    name: "BlogCategoriesList",
    component: BlogCategoriesList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.BLOG_CATEGORY_CREATE,
    name: "BlogCategoryCreate",
    component: BlogCategoryCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.BLOG_CATEGORY_EDIT,
    name: "BlogCategoryEdit",
    component: BlogCategoryEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  {
    path: ROUTE.BLOG_POSTS_LIST,
    name: "BlogPostsList",
    component: BlogPostsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.BLOG_POST_CREATE,
    name: "BlogPostCreate",
    component: BlogPostCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.BLOG_POST_EDIT,
    name: "BlogPostEdit",
    component: BlogPostEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Users Pages

  {
    path: ROUTE.USERS_LIST,
    name: "UsersList",
    component: UsersList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.USER_CREATE,
    name: "UserCreate",
    component: UserCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.USER_EDIT,
    name: "UserEdit",
    component: UserEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.USER_DETAIL,
    name: "UserDetail",
    component: UserDetail,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Blog Post Statuses Pages

  {
    path: ROUTE.BLOG_POST_STATUSES_LIST,
    name: "BlogPostStatusesList",
    component: BlogPostStatusesList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.BLOG_POST_STATUS_CREATE,
    name: "BlogPostStatusCreate",
    component: BlogPostStatusCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.BLOG_POST_STATUS_EDIT,
    name: "BlogPostStatusEdit",
    component: BlogPostStatusEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  //Users Groups Pages

  {
    path: ROUTE.USER_GROUPS_LIST,
    name: "UserGroupsList",
    component: UserGroupsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.USER_GROUP_CREATE,
    name: "UserGroupCreate",
    component: UserGroupCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.USER_GROUP_EDIT,
    name: "UserGroupEdit",
    component: UserGroupEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Subscription

  {
    path: ROUTE.SUBSCRIPTION_LIST,
    name: "SubscriptionList",
    component: SubscriptionList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.SUBSCRIPTION_CREATE,
    name: "SubscriptionCreate",
    component: SubscriptionCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.SUBSCRIPTION_EDIT,
    name: "SubscriptionEdit",
    component: SubscriptionEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // FAQ Group

  {
    path: ROUTE.FAQ_GROUP_LIST,
    name: "FaqGroupsList",
    component: FaqGroupsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.FAQ_GROUP_CREATE,
    name: "FaqGroupCreate",
    component: FaqGroupCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.FAQ_GROUP_EDIT,
    name: "FaqGroupEdit",
    component: FaqGroupEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // FAQ Item

  {
    path: ROUTE.FAQ_ITEM_LIST,
    name: "FaqItemsList",
    component: FaqItemsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.FAQ_ITEM_CREATE,
    name: "FaqItemCreate",
    component: FaqItemCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.FAQ_ITEM_EDIT,
    name: "FaqItemEdit",
    component: FaqItemEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Statics

  {
    path: ROUTE.STATIC_LIST,
    name: "StaticsList",
    component: StaticsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.STATIC_CREATE,
    name: "StaticsCreate",
    component: StaticsCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.STATIC_EDIT,
    name: "StaticsEdit",
    component: StaticsEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Social

  {
    path: ROUTE.SOCIAL_FB,
    name: "fbList",
    component: fbList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Indexing

  {
    path: ROUTE.INDEXING_LIST,
    name: "IndexingList",
    component: IndexingList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Test editor

  {
    path: ROUTE.EDITOR,
    name: "EditorCreate",
    component: EditorCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Languages

  {
    path: ROUTE.LANG_LIST,
    name: "LangList",
    component: LangList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.LANG_CREATE,
    name: "LangCreate",
    component: LangCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.LANG_EDIT,
    name: "LangEdit",
    component: LangEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Seo

  {
    path: ROUTE.SEO_LIST,
    name: "SeoList",
    component: SeoList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.SEO_CREATE,
    name: "SeoCreate",
    component: SeoCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.SEO_EDIT,
    name: "SeoEdit",
    component: SeoEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // ContentPlans

  {
    path: ROUTE.PLANS_LIST,
    name: "PlansList",
    component: PlansList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.PLANS_ITEMS,
    name: "PlanItemsList",
    component: PlanItemsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // Keywords

  {
    path: ROUTE.KEYWORDS_LIST,
    name: "KeywordsList",
    component: KeywordsList,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.KEYWORDS_CREATE,
    name: "KeywordsCreate",
    component: KeywordsCreate,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.KEYWORDS_CREATE_ARRAY,
    name: "KeywordsCreateArray",
    component: KeywordsCreateArray,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: ROUTE.KEYWORDS_EDIT,
    name: "KeywordsEdit",
    component: KeywordsEdit,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },

  // 404 routes

  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",

  base: process.env.BASE_URL,

  routes,
});

export default router;
