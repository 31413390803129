import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getLangList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.LANG + url,
      })
      .then((res) => res.data);
  },

  createLang(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LANG,
        data,
      })
      .then((res) => res.data);
  },

  editLang(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.LANG + url,
        data,
      })
      .then((res) => res.data);
  },

  getLangItem(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.LANG + url,
      })
      .then((res) => res.data);
  },

  deleteLang(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.LANG + url,
      })
      .then((res) => res.data);
  },

  // Chat GPT

  getTranslation(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.TRANSLATION,
        data,
      })
      .then((res) => res.data);
  },

  // Color

  createLangColor(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LANG_COLOR,
        data,
      })
      .then((res) => res.data);
  },

  editLangColor(data, url = "") {
    return callApi
      .request({
        method: "PUT",
        url: URL.LANG_COLOR + url,
        data,
      })
      .then((res) => res.data);
  },

  // Style

  createLangStyle(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LANG_STYLE,
        data,
      })
      .then((res) => res.data);
  },

  editLangStyle(data, url = "") {
    return callApi
      .request({
        method: "PUT",
        url: URL.LANG_STYLE + url,
        data,
      })
      .then((res) => res.data);
  },

  // Template

  createLangTemplate(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LANG_TEMPLATE,
        data,
      })
      .then((res) => res.data);
  },

  editLangTemplate(data, url = "") {
    return callApi
      .request({
        method: "PUT",
        url: URL.LANG_TEMPLATE + url,
        data,
      })
      .then((res) => res.data);
  },

  // Category

  createLangCategory(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LANG_CATEGORY,
        data,
      })
      .then((res) => res.data);
  },

  editLangCategory(data, url = "") {
    return callApi
      .request({
        method: "PUT",
        url: URL.LANG_CATEGORY + url,
        data,
      })
      .then((res) => res.data);
  },

  // Static

  createLangStatic(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LANG_STATIC,
        data,
      })
      .then((res) => res.data);
  },

  editLangStatic(data, url = "") {
    return callApi
      .request({
        method: "PUT",
        url: URL.LANG_STATIC + url,
        data,
      })
      .then((res) => res.data);
  },

  // FAQ

  createLangFaq(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LANG_FAQ,
        data,
      })
      .then((res) => res.data);
  },

  editLangFaq(data, url = "") {
    return callApi
      .request({
        method: "PUT",
        url: URL.LANG_FAQ + url,
        data,
      })
      .then((res) => res.data);
  },

  // SEO

  createLangSeo(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.LANG_SEO,
        data,
      })
      .then((res) => res.data);
  },

  editLangSeo(data, url = "") {
    return callApi
      .request({
        method: "PUT",
        url: URL.LANG_SEO + url,
        data,
      })
      .then((res) => res.data);
  },
};
